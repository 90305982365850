<template>
    <div>
        绩效综合管理
    </div>
</template>

<script>
export default {
    data() {
        return {};
    },
};
</script>

<style scoped>
</style>
